<template>
  <div class="container py-5">
    <p class="fw-bold">第十屆全國微創醫學美容大會</p>
    <p>時間：2023 03/31-04/02</p>
    <p>地點：重慶悅來國際會議中心</p>
    <p>展位：149-152</p>
    <p>
      此次受邀出席會議，銘伊恆美將攜荷蘭OBSERV全面部肌膚影像分析儀，山西鍶為智能生產製造的伊諾鍶黃金微針等儀器，展現權威前沿科技，與大家共同交流探討新技術的臨床應用等。
      <br>
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_17_1.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
